<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">网关管理</h1>
    </div>
    <div slot="extra">
      <a-button @click="handleAdd" icon="plus" type="primary" v-auth="auth_equipment2.GATEWAY_CREATE_AND_UPDATE"
        >新增</a-button
      >
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="厂家类型">
              <a-select allowClear showSearch v-model="searchParams.type" placeholder="请选择厂家类型" @change="search">
                <a-select-option
                  v-for="(item, index) in companyTypeList"
                  :key="index"
                  :value="item.value"
                  :label="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="网关编号">
              <a-input v-model="searchParams.deviceGatewayNo" @change="search" placeholder="请输入网关编号" />
            </a-form-item>
            <a-form-item label="网关名称">
              <a-input v-model="searchParams.name" @change="search" placeholder="请输入网关名称" />
            </a-form-item>

            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        bordered
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :customRow="handleView"
        :dataSource="tableData"
        :loading="tableLoading"
        :scroll="{ x: 1400 }"
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="deviceGatewayNo" slot-scope="text, record">
          {{ record.deviceGatewayNo }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a type="link" @click="handleEdit(record.id)" v-auth="auth_equipment2.GATEWAY_CREATE_AND_UPDATE" @click.stop
            >编辑</a
          >
          <a-divider type="vertical" />
          <a type="link" @click="handleDelete(record.id)" v-auth="auth_equipment2.GATEWAY_DELETE" @click.stop>删除</a>
        </template>
        <template slot="typeSlot" slot-scope="text, record">
          <span v-if="record.type === 'jk'">GRM云服务器(巨控)</span>
          <span v-if="record.type === 'lyyjkj'">洛阳愿景科技有限公司</span>
          <span v-if="record.type === 'tspsdzjs'">唐山平升电子技术开发有限公司</span>
          <span v-if="record.type === 'shktyb'">上海肯特仪表股份有限公司</span>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal
      title="添加网关基本信息"
      :width="700"
      :visible="addModal"
      :maskClosable="false"
      @ok="handleAddOk"
      @cancel="closeAddModal"
    >
      <Form ref="addRef" type="add" @addSuccess="addSuccess" @addError="addError"> </Form>
    </a-modal>
    <!--修改Model-->
    <a-modal
      title="修改网关基本信息"
      :width="700"
      :visible="editModal"
      :maskClosable="false"
      @ok="handleEditOk"
      @cancel="closeEditModal"
    >
      <Form ref="editRef" type="edit" @editSuccess="editSuccess" @editError="editError"> </Form>
    </a-modal>
    <a-drawer
      title="网关详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      width="450px"
      :visible="detailDrawer"
      :mask="false"
    >
      <gateway-detail ref="viewRef"></gateway-detail>
    </a-drawer>
  </page-layout>
</template>

<script>
import SERVICE_URLS from '@/api/service.url'
import entityCRUD from '../../common/mixins/entityCRUD'
import { gatewayColumns, companyTypeList } from './common/common'
import PageLayout from '@/components/page/PageLayout'
import Form from './Form'
import gatewayDetail from './Detail'
export default {
  name: 'List',
  mixins: [entityCRUD],
  components: { PageLayout, Form, gatewayDetail },
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.equipment2.gatewayApi,
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        deviceGatewayNo: '',
        name: '',
        type: undefined,
      },
      companyTypeList: companyTypeList(),
      initColumns: gatewayColumns(),
    }
  },
  methods: {
    handleView(record) {
      return {
        on: {
          click: () => {
            this.detailDrawer = true
            this.$nextTick(() => {
              this.$refs.viewRef.loadData(record.id)
            })
          },
        },
      }
    },
  },
}
</script>
